import React from "react"
import PropTypes from "prop-types"
// import { graphql, navigateTo, useStaticQuery } from "gatsby"
import { navigateTo } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import Pagination from "@material-ui/lab/Pagination"
import themeOptions from "../../../.brew-cache/theme.json"
import Link from "../Link"
import Typography from "@material-ui/core/Typography/Typography"
import Img from "gatsby-image"
import { replaceMasks } from "../../services/masks"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BlogListing = props => {
  const classes = useStyles()

  let { posts, pageNumber, totalPages } = props.pageContext

  // Toggle output based on Attributes
  // props.attributes.display : list / grid

  // Query out the sticky post
  //   const data = useStaticQuery(graphql`
  //     query CatListQuery {
  //       wp {
  //         categories(where: { orderby: TERM_ORDER }, first: 100) {
  //           nodes {
  //             id
  //             uri
  //             slug
  //             name
  //           }
  //         }
  //         locationData {
  //           LocationData {
  //             facebookHandle
  //             instagramHandle
  //             twitterHandle
  //           }
  //         }
  //       }
  //     }
  //   `)

  if (typeof posts === "undefined") {
    // set some sample data
    posts = [
      {
        node: {
          title: "Example Post",
          date: "2020-01-01 00:00:00",
          excerpt:
            "<p>This is just a test post used for previewing the listing page. Real posts will appear here when this page is published.</p>",
          uri: "/",
          featuredImage: {
            node: {
              mediaItemUrl: "",
            },
          },
        },
      },
    ]
  }

  var dateFormat = require("dateformat")

  let featuredSticky = false
  const featured = {}
  if (props.sticky.nodes.length === 1) {
    // Use the sticky post first
    featuredSticky = props.sticky.nodes[0].id
    let image = null

    if (props.sticky.nodes[0].featuredImage.node.mediaItemUrlSharp) {
      if (
        props.sticky.nodes[0].featuredImage.node.mediaItemUrlSharp
          .childImageSharp
      ) {
        image = (
          <Img
            fluid={
              props.sticky.nodes[0].featuredImage.node.mediaItemUrlSharp
                .childImageSharp.fluid
            }
            alt=""
          />
        )
      } else {
        // Gifs do not have a childImageSharp
        image = (
          <img
            src={
              props.sticky.nodes[0].featuredImage.node.mediaItemUrlSharp
                .publicURL
            }
            alt=""
          />
        )
      }
    } else {
      // This is a preview image
      image = (
        <div className="gatsby-image-wrapper">
          <div style={{ paddingBottom: "55%" }} />
          <img
            src={props.sticky.nodes[0].featuredImage.node.mediaUrl}
            alt=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </div>
      )
    }

    featured.id = props.sticky.nodes[0].id
    featured.uri = props.sticky.nodes[0].slug
    featured.image = image
    featured.title = props.sticky.nodes[0].title
    featured.date = new Date(props.sticky.nodes[0].date)
    featured.date = dateFormat(featured.date, "dS mmmm yyyy")
  } else {
    if (posts.length > 0) {
      let image = null
      if (posts[0].node.featuredImage.node.mediaItemUrlSharp) {
        if (
          posts[0].node.featuredImage.node.mediaItemUrlSharp.childImageSharp
        ) {
          image = (
            <Img
              fluid={
                posts[0].node.featuredImage.node.mediaItemUrlSharp
                  .childImageSharp.fluid
              }
              alt={posts[0].node.featuredImage.node.altText}
            />
          )
        } else {
          // Gifs do not have a childImageSharp
          image = (
            <img
              src={posts[0].node.featuredImage.node.mediaItemUrlSharp.publicURL}
              alt={posts[0].node.featuredImage.node.altText}
            />
          )
        }
      } else {
        // This is a preview image
        image = (
          <div className="gatsby-image-wrapper">
            <div style={{ paddingBottom: "55%" }} />
            <img
              src={posts[0].node.featuredImage.node.mediaUrl}
              alt={posts[0].node.featuredImage.node.altText}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
              }}
            />
          </div>
        )
      }

      featured.id = posts[0].node.id
      featured.uri = posts[0].node.slug
      featured.image = image
      featured.title = posts[0].node.title
      featured.date = new Date(posts[0].node.date)
      featured.date = dateFormat(featured.date, "dS mmmm yyyy")
    }
  }

  if (props.cat.name === "All") {
    props.cat.name = "Latest"
  }

  return (
    <div
      className={`cgbBlogPagePostsBlock ${classes.blogPagePosts} ${classes.blogPagePostsTheme}`}
    >
      <div className="blog-list-banner">
        <Typography component="h1" variant="h1" color="initial" align="left">
          {props.cat.name}
        </Typography>
      </div>
      <div className="blog-list">
        <div className="featured-item">
          <Link to={`/${themeOptions.blogPath}/${featured.uri}`}>
            {featured.image}
            <div className="cta-wrap">
              <span className="date">{featured.date}</span>
              <span className="post-title">{featured.title}</span>
              <span className="cta">Read More</span>
            </div>
          </Link>
        </div>
        {posts.map((post, index) => {
          if (
            (index === 0 && featuredSticky === false) ||
            post.node.id === featured.id
          )
            return false
          let image = null
          if (post.node.featuredImage.node.mediaItemUrlSharp) {
            if (
              post.node.featuredImage.node.mediaItemUrlSharp.childImageSharp
            ) {
              image = (
                <Img
                  fluid={
                    post.node.featuredImage.node.mediaItemUrlSharp
                      .childImageSharp.fluid
                  }
                  alt={post.node.featuredImage.node.altText}
                />
              )
            } else {
              // Gifs do not have a childImageSharp
              image = (
                <img
                  src={post.node.featuredImage.node.mediaItemUrlSharp.publicURL}
                  alt={post.node.featuredImage.node.altText}
                />
              )
            }
          } else {
            // This is a preview image
            image = (
              <div className="gatsby-image-wrapper">
                <div style={{ paddingBottom: "78%" }} />
                <img
                  src={post.node.featuredImage.node.mediaUrl}
                  alt={post.node.featuredImage.node.altText}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                  }}
                />
              </div>
            )
          }
          let date = ""
          date = new Date(post.node.date)
          date = dateFormat(date, "dS mmmm yyyy")
          return (
            <div className="list-item" key={post.node.id}>
              {image}
              <div className="con-wrap">
                <p className="date">{date}</p>
                <h2>{post.node.title}</h2>{" "}
                <p>
                  {replaceMasks(post.node.excerpt.replace(/(<([^>]+)>)/gi, ""))}
                </p>
                <Link
                  to={`/${themeOptions.blogPath}${post.node.uri}`}
                  style={{ textDecoration: "none" }}
                  className="cta"
                >
                  Read More
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={pageNumber}
          onChange={(e, value) => {
            value === 1
              ? navigateTo(`/${themeOptions.blogPath}`)
              : navigateTo(`/${themeOptions.blogPath}/page/${value}`)
          }}
        />
      )}
    </div>
  )
}

BlogListing.propTypes = {
  attributes: PropTypes.object.isRequired,
}
